.subheader {
    text-align: justify !important;
  }

.pc-network {
  vertical-align: middle !important;
}

.pc-network {
  vertical-align: middle !important;
}
.wallet-address {
  font-family: monospace;
  width: 150px; /* Adjust as needed */
  text-align: center;
  display: inline-block;
}


